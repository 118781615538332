import React from 'react'
import html from "../assets/html.png";
import css from "../assets/css.png";
import javascript from "../assets/javascript.png";
import reactImage from "../assets/react.png";
import github from "../assets/github.png";
import aplus2 from "../assets/aplus2.png";
import wordpress from "../assets/wordpress.png";
import sql from "../assets/sql.png";

const Experience = () => {

    const techs = [
        {
            id: 1,
            src: html,
            title: 'HTML',
            style: 'shadow-orange-500'
        },
        {
            id: 2,
            src: css,
            title: 'CSS',
            style: 'shadow-blue-500'
        },
        {
            id: 3,
            src: javascript,
            title: 'JavaScript',
            style: 'shadow-yellow-500'
        },
        {
            id: 4,
            src: reactImage,
            title: 'React',
            style: 'shadow-teal-500'
        },
        {
            id: 5,
            src: github,
            title: 'GitHub',
            style: 'shadow-gray-500'
        },
        {
            id: 6,
            src: aplus2,
            title: 'A+ Certified',
            style: 'shadow-red-500'
        },
        {
            id: 7,
            src: wordpress,
            title: 'WordPress',
            style: 'shadow-blue-500'
        },
        {
            id: 8,
            src: sql,
            title: 'SQL',
            style: 'shadow-indigo-500'
        },
    ]

  return (
    <div name="experience" 
    className='bg-gradient-to-b from-gray-800 to-black w-full h-screen, pb-8'>
        <div className="max-w-screen-lg mx-auto p-4 flex flex-col
        justify-center w-full h-full text-white">
      
            <div>
                <p className='text-4xl font-bold p-2 inline border-b-4
                border-gray-500'>Experience</p>
                <p className='py-6'>Technical Skills</p>
            </div>

            <div className="w-full grid grid-cols-2 sm:grid-cols-3
            gap-8 text-center py-8 px-12 sm:px-0">

                {
                    techs.map(({id, src, title, style}) => (
                        <div key={id}
                        className={`shadow-md hover:scale-105 duration-500
                        py-2 rounded-lg ${style}`}>

                        <img src={src} alt="" className='w-20 mx-auto' />
                        <p className='mt-4'>{title}</p>
                </div>
                    ))}

            </div>
        </div>
    </div>
  );
};

export default Experience