import React from "react";

const Contact = () => {
  return (
    <div name="contact" className="w-full h-full md:h-screen bg-gradient-to-b 
    from-black to-gray-800 py-4 text-white ">
      <div className="max-w-screen-lg mx-auto flex flex-col p-4 justify-center 
       h-full">
        <div>
          <p className="text-4xl font-bold inline border-b-4
          border-gray-500">Contact</p>
          <p className="py-6">Submit form below to reach me</p>
        </div>

        <div className="flex justify-center items-center">
          <form action="https://getform.io/f/52bee52a-2712-41b7-843e-0e0987bd6919" method="POST" 
          className="flex flex-col w-full md:w-1/2">
            <input
              type="text"
              name="name"
              placeholder="Enter your name"
              className="p-2 bg-transparent border-2 rounded-md
               text-white focus:outline-none"
            />

            <input
              type="text"
              name="email"
              placeholder="Enter your email"
              className="my-4 p-2 bg-transparent border-2 rounded-md
               text-white focus:outline-none"
            />

            <textarea
              name="message"
              placeholder="Enter Message Here"
              rows="10"
              className="p-2
            bg-transparent border-2 rounded-md text-white
            focus:outline-none"
            ></textarea>

            <button
              className="text-white bg-gradient-to-b from-cyan-500
            to-blue-500 px-6 py-3 my-8 mx-auto flex
            items-center rounded-md hover:scale-110 duration-300"
            >
              Let's connect
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
